<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="52"
      height="52"
      rx="15"
      :fill="getAssets.primaryBrandColor + '20' || '#00000020'"
    />
    <path
      d="M20.5002 16.8333C18.4751 16.8333 16.8335 18.475 16.8335 20.5V25.0833H26.5371L24.4353 22.9815C24.0773 22.6235 24.0773 22.0431 24.4353 21.6851C24.7933 21.3272 25.3737 21.3272 25.7317 21.6851L29.3983 25.3518C29.7563 25.7098 29.7563 26.2902 29.3983 26.6482L25.7317 30.3148C25.3737 30.6728 24.7933 30.6728 24.4353 30.3148C24.0773 29.9569 24.0773 29.3765 24.4353 29.0185L26.5371 26.9167H16.8335V31.5C16.8335 33.525 18.4751 35.1667 20.5002 35.1667H31.5002C33.5252 35.1667 35.1668 33.525 35.1668 31.5V20.5C35.1668 18.475 33.5252 16.8333 31.5002 16.8333H20.5002Z"
      :fill="getAssets.primaryBrandColor || '#000000'"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Logout-Icon",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>
